import stepPrepareDocumentsPocket from './steps-pocket/step-prepare-documents-pocket.json';
import stepChooseGoalsPocket from './steps-pocket/step-choose-goals-pocket.json';
import stepDistributeBalancePocket from './steps-pocket/step-distribute-balance-pocket.json';
import stepScheduleReminderPocket from './steps-pocket/step-schedule-reminder-pocket.json';
import stepSuccessPocket from './steps-pocket/step-success-pocket.json';
import stepPrepareDocumentsWealth from './steps-wealth/step-prepare-documents-wealth.json';
import stepChooseGoalsWealth from './steps-wealth/step-choose-goals-wealth.json';
import stepSuccessWealth from './steps-wealth/step-success-wealth.json';
import stepDistributeBalanceWealth from './steps-wealth/step-distribute-balance-wealth.json';
import stepConfigureContributionsWealth from './steps-wealth/step-configure-contributions-wealth.json';
import stepConfigureSecondContributionWealth from './steps-wealth/step-configure-second-contribution-wealth.json';
import stepSchedulePaymentDateWealth from './steps-wealth/step-schedule-payment-date-wealth.json';
import modalPrepareDocumentsPocket from './steps-pocket/modal-prepare-documents-pocket.json';
import modalPrepareDocumentsWealth from './steps-wealth/modal-prepare-documents-wealth.json';

export default {
  'flagship-link-goals': {
    pocket: {
      title_activate: 'Activar Pocket',
      title_link: 'Vincular con Pocket',
      steps_activate_pocket: [
        'Prepara tus documentos',
        'Elige las metas',
        'Distribuye tu saldo',
        'Programa un recordatorio',
      ],
      steps_link_pocket_with_balance: [
        'Elige las metas',
        'Distribuye tu saldo',
        'Programa un recordatorio',
      ],
      steps_link_pocket_without_balance: [
        'Elige las metas',
        'Programa un recordatorio',
      ],
      errors: {
        get_kuspit_position: 'Ocurrió un error al obtener la información del saldo de mi cuenta Kuspit.',
        get_customer_investor_goal_balance: 'Ocurrió un error al obtener la información del saldo asignado de la meta.',
        load_active_goals: 'Ocurrió un error al obtener sus metas activas',
      },
    },
    wealth: {
      title_activate: 'Activar Wealth',
      title_link: 'Vincular con Wealth',
      steps_activate_wealth: [
        'Prepara tus documentos',
        'Elige las metas',
        'Distribuye tu saldo',
        'Configura tus aportaciones',
        'Programa tu fecha de cobro',
      ],
      steps_link_wealth_with_balance: [
        'Elige las metas',
        'Distribuye tu saldo',
        'Configura tus aportaciones',
        'Programa tu fecha de cobro',
      ],
      steps_link_wealth_without_balance: [
        'Elige las metas',
        'Configura tus aportaciones',
        'Programa tu fecha de cobro',
      ],
      errors: {
        get_allianz_balance: 'Ocurrió un error al obtener su saldo de Wealth',
        get_customer_investor_goal_balance: 'Ocurrió un error al obtener la información del saldo asignado de la meta.',
        load_active_goals: 'Ocurrió un error al obtener sus metas activas',
      },
    },
    alts: {
      img_sowos: 'Imagen con las letras de la palabra SOWOS.',
      img_goals: 'Imagen de una mujer recargada en una torre con una bandera en la mano, que simboliza lograr las metas.',
    },
    'step-prepare-documents-pocket': stepPrepareDocumentsPocket,
    'step-choose-goals-pocket': stepChooseGoalsPocket,
    'step-distribute-balance-pocket': stepDistributeBalancePocket,
    'step-schedule-reminder-pocket': stepScheduleReminderPocket,
    'step-success-pocket': stepSuccessPocket,
    'step-prepare-documents-wealth': stepPrepareDocumentsWealth,
    'step-choose-goals-wealth': stepChooseGoalsWealth,
    'step-distribute-balance-wealth': stepDistributeBalanceWealth,
    'step-configure-contributions-wealth': stepConfigureContributionsWealth,
    'step-configure-second-contribution-wealth': stepConfigureSecondContributionWealth,
    'step-schedule-payment-date-wealth': stepSchedulePaymentDateWealth,
    'step-success-wealth': stepSuccessWealth,
    'modal-prepare-documents-pocket': modalPrepareDocumentsPocket,
    'modal-prepare-documents-wealth': modalPrepareDocumentsWealth,
  },
};
