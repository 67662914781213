import { Container } from 'inversify-props';
import setupAllianzAccount from './allianz-account/setup';
import setupAllianzAccountReport from './allianz-account-report/setup';
import setupAllianzEarningsReport from './allianz-earnings-report/setup';
import setupAllianzAccountMovementsReport from './allianz-account-movement-report/setup';
import setupTransaction from './transaction/setup';
import setupInvestmentType from './investment-type/setup';
import setupAllianzFund from './allianz-funds/setup';
import setupAllianzOperationDates from './allianz-operation-dates/setup';
import setupTransactionType from './transaction-type/setup';
import setupTransactionStatus from './transaction-status/setup';
import setupCurrencyType from './currency-type/setup';
import setupAllianzCustomerAvailableBalance from './allianz-customer-available-balance/setup';
import setupAllianzPolicyIssuanceStatusAccount from './allianz-policy-issuance-status/setup';
import setupMyInvestmentAllianzOnBoardingStatusModule from './on-boarding-status/setup';
import setupPolicy from './policy/setup';
import setupTotalWithdrawal from '@/modules/my-investment/allianz/total_withdrawal/setup';
import setupAllianzAccountStatement from '@/modules/my-investment/allianz/allianz-account-statement/setup';
import setupActivateSowosModerate from './activate-sowos-moderate/setup';
import setupSpecialContribution from './special-contribution/setup';
import setupAllianzRequestForMovementsToPolicy from './allianz-request-for-movments-to-policy/setup';
import setupAccessMoreStrategies from '@/modules/my-investment/allianz/access-more-strategies/setup';
import setupInvestmentTypeHiringStep from '@/modules/my-investment/allianz/investment-type-hiring-step/setup';
import setupInvestmentProductFundType from '@/modules/my-investment/allianz/investment-product-fund-type/setup';
import setupAllianzRecurringContributions from '@/modules/my-investment/allianz/recurring-contributions/setup';

export default function setupAllianz(container: Container) {
  setupAllianzAccount(container);
  setupAllianzAccountReport(container);
  setupAllianzEarningsReport(container);
  setupAllianzAccountMovementsReport(container);
  setupTransaction(container);
  setupInvestmentType(container);
  setupAllianzFund(container);
  setupAllianzOperationDates(container);
  setupTransactionType(container);
  setupTransactionStatus(container);
  setupCurrencyType(container);
  setupAllianzCustomerAvailableBalance(container);
  setupAllianzPolicyIssuanceStatusAccount(container);
  setupMyInvestmentAllianzOnBoardingStatusModule(container);
  setupPolicy(container);
  setupTotalWithdrawal(container);
  setupAllianzAccountStatement(container);
  setupActivateSowosModerate(container);
  setupSpecialContribution(container);
  setupAllianzRequestForMovementsToPolicy(container);
  setupAccessMoreStrategies(container);
  setupInvestmentTypeHiringStep(container);
  setupInvestmentProductFundType(container);
  setupAllianzRecurringContributions(container);
}
