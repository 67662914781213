import fundKuspitAccount from './fund-kuspit-account.json';
import myPersonalBalance from './my-personal-balance.json';
import membership from './membership.json';
import sowosWealthCard from './sowos-wealth-card.json';
import sowosPocketCard from './sowos-pocket-card.json';
import inviteFriend from './invite-friend.json';
import flagshipProfilingCard from './flagship_profiling_card.json';
import flagshipDefineGoals from './flagship_define_goals_card.json';
import flagshipDefineGoalsDialog from './flagship_define_goals_dialog.json';
import pocketIsOnMaintenance from './pocket-is-on-maintenance.json';
import productIsUnavailableToHire from './product-is-unavailable-to-hire.json';

export default {
  'my-investment-dashboard': {
    title: 'Principal',
    general_balance: 'Saldo general:',
    update_date: 'Fecha de actualización: {date}',
    my_investment_accounts: 'Mis Cuentas de Inversión',
    pocket: 'SOWOS Pocket',
    pocket_name: 'Kuspit Casa de Bolsa',
    pocket_description: 'Una cuenta de inversión flexible con liquidez total.',
    wealth: 'SOWOS Wealth',
    wealth_name: 'Seguro Inversión Allianz',
    wealth_description: 'Un ahorro automático con grandes  beneficios respaldada por Allianz.',
    view_detail: 'Ver detalle',
    view_details: 'Ver detalles',
    has_account: 'Ya tienes una cuenta de ahorro.',
    fund_account: 'Fondea tu cuenta para acceder a SOWOS Pocket',
    fund: 'Fondear',
    application_under_review: 'Tu solicitud está en revisión',
    policy_status_notifier: 'Te notificaremos cuando tu póliza sea emitida',
    new_alternatives: 'Nuevas alternativas',
    back: 'Regresar',
    request_withdrawal: 'Solicitar retiro',
    make_contribution: 'Realizar depósito',
    invest_with: 'Invierte desde Allianz',
    save_with: 'Ahorra desde Kuspit',
    'fund-kuspit-account': fundKuspitAccount,
    membership,
    more_benefits: 'Mis Metas',
    'my-personal-balance': myPersonalBalance,
    'sowos-wealth-card': sowosWealthCard,
    'sowos-pocket-card': sowosPocketCard,
    'invite-friend': inviteFriend,
    'flagship-profiling-card': flagshipProfilingCard,
    'flagship-define-goals': flagshipDefineGoals,
    'flagship-define-goals-dialog': flagshipDefineGoalsDialog,
    ...pocketIsOnMaintenance,
    ...productIsUnavailableToHire,
    errors: {
      policy_in_process_of_cancellation: 'No fue posible obtener el estatus de cancelación.',
    },
  },
};
