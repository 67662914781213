import catalogsTypes from './catalogs/types';
import customGoalCalculationsTypes from './custom-goal-calculations/types';
import customInvestorGoalTypes from './custom-investor-goal/types';
import emergencyFundInvestorGoalTypes from './emergency-fund-investor-goal/types';
import emergencyFundCalculationTypes from './emergency-fund-calculations/types';
import retirementInvestorGoalTypes from './retirement-investor-goal/types';
import habitsAvailableBudgetCalculationTypes from './habits-available-budget-calculation/types';
import habitsRecommendedBudgetCalculationTypes from './habits-recommended-budget-calculation/types';
import retirementForecastCalculationTypes from './retirement-forecast-calculation/types';
import investorProfileModuleTypes from './investor-profile/types';
import investmentProfileTypes from './investment-product/types';
import retirementGoalCalculationsTypes from './retirement-goal-calculations/types';
import customerContractedInvestmentProductsTypes from './customer-contracted-investment-products/types';
import overallInvestmentAmountTypes from './overall-investment-amount-calculation/types';
import pendingInvestorGoalsMovementsTypes from './pending-investor-goal-movements/types';
import investorGoalTypes from './investor-goal/types';
import investorGoalScheduledDistributionTypes from './investor-goal-scheduled-distribution/types';
import customerInvestorGoalReminderTypes from './customer-investor-goal-reminder/types';
import customerInvestorGoalBalanceTypes from './customer-investor-goal-balance/types';
import goalDefinitionStepTypes from './goal-definition-step/types';
import customerInvestorProfilingTypes from './customer-investor-profiling/types';
import customerInvestmentProductFundType
  from './customer-investment-product-fund-type/types';
import contactPointsFundTypes from './contact-points/types';
import strategySowosModerateTypes from './strategy-moderate/types';
import editPlanGoalsTypes from './edit-plan-goals/types';
import emergencyFundInvestorGoalCalculatorTypes from './emergency-fund-investor-goal-calculator/types';
import retirementFundInvestorGoalCalculatorTypes from './retirement-investor-goal-calculator/types';
import customInvestorGoalCalculatorTypes from './custom-investor-goal-calculator/types';
import riskToleranceQuestionsTypes from './risk-tolerance-questions/types';
import investorGoalRiskToleranceTypes from './investor-goal-risk-tolerance/types';
import investorGoalPauseTrackingReasonTypes from './investor-goal-pause-tracking-reason/types';
import bucketInvestorGoalTypes from './bucket-investor-goal/types';

export default {
  ...catalogsTypes,
  ...customInvestorGoalTypes,
  ...emergencyFundInvestorGoalTypes,
  ...emergencyFundCalculationTypes,
  ...retirementInvestorGoalTypes,
  ...customGoalCalculationsTypes,
  ...retirementGoalCalculationsTypes,
  ...habitsAvailableBudgetCalculationTypes,
  ...habitsRecommendedBudgetCalculationTypes,
  ...retirementForecastCalculationTypes,
  ...investorProfileModuleTypes,
  ...investmentProfileTypes,
  ...customerContractedInvestmentProductsTypes,
  ...overallInvestmentAmountTypes,
  ...pendingInvestorGoalsMovementsTypes,
  ...investorGoalTypes,
  ...investorGoalScheduledDistributionTypes,
  ...customerInvestorGoalReminderTypes,
  ...customerInvestorGoalBalanceTypes,
  ...goalDefinitionStepTypes,
  ...customerInvestorProfilingTypes,
  ...customerInvestmentProductFundType,
  ...contactPointsFundTypes,
  ...strategySowosModerateTypes,
  ...editPlanGoalsTypes,
  ...emergencyFundInvestorGoalCalculatorTypes,
  ...retirementFundInvestorGoalCalculatorTypes,
  ...customInvestorGoalCalculatorTypes,
  ...riskToleranceQuestionsTypes,
  ...investorGoalRiskToleranceTypes,
  ...investorGoalPauseTrackingReasonTypes,
  ...bucketInvestorGoalTypes,
};
