import TYPES from '../../types';

// Domain
import { AllianzRecurringContributionsEntity } from '../../domain/entities/allianz-recurring-contributions-entity';
import { AllianzRecurringContributionsRepository } from '../../domain/repository/allianz-recurring-contributions-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAllianzRecurringContributionsQuery
  extends Query<Promise<AllianzRecurringContributionsEntity>> {
  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_REPOSITORY)
  private readonly repository!: AllianzRecurringContributionsRepository;

  internalExecute(reload = false): Promise<AllianzRecurringContributionsEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded || reload) {
        this.state_manager.patch({ is_loading: true });
        this.repository.get()
          .then((item) => {
            this.state_manager.patch({ item, loaded: true });
            resolve(item);
          })
          .catch(reject)
          .finally(() => {
            this.state_manager.patch({ is_loading: false });
          });
      } else {
        resolve(this.state_manager.state.item);
      }
    });
  }
}
