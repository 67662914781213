import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import CustomInvestorGoalVueStateManager
  from '@/modules/flagship/custom-investor-goal/infrastructure/state/custom-investor-goal-vue-state-manager';
import CustomInvestorGoalDummyRepository
  from '@/modules/flagship/custom-investor-goal/infrastructure/repositories/custom-investor-goal-dummy-repository/custom-investor-goal-dummy-repository';
import CustomInvestorGoalHttpRepository
  from '@/modules/flagship/custom-investor-goal/infrastructure/repositories/custom-investor-goal-http-repository';
import SearchByCustomerVueStateManager
  from '@/modules/flagship/custom-investor-goal/infrastructure/state/search-by-customer-vue-state-manager';
import DeleteCustomInvestorGoalVueStateManager
  from '@/modules/flagship/custom-investor-goal/infrastructure/state/delete-custom-investor-goal-vue-state-manager';

// Application
import GetCustomInvestorGoalQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-query';
import GetCustomInvestorGoalSearchByCustomerQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import UpdateCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-command';
import CreateCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-command';
import CreateCustomInvestorGoalWithContributionsCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-with-contributions-command';
import DeleteCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/delete-custom-investor-goal-command';
import UpdateCustomInvestorGoalNameCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-name-command';
import TransferBalanceCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/transfer-balance-custom-investor-goal-command';
import UpdatePlanCustomGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-plan-custom-goal-command';
import DeleteCustomInvestorGoalStateManagerService
  from '@/modules/flagship/custom-investor-goal/application/service/delete-custom-investor-goal-state-manager-service';

// Domain
import {
  CustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/custom-investor-goal-state-manager';
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import {
  SearchByCustomerStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/search-by-customer-state-manager';
import {
  DeleteCustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state-manager';

export default function setupCustomInvestorGoal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomInvestorGoalStateManager>(TYPES.CUSTOM_INVESTOR_GOAL_STATE_MANAGER)
    .to(CustomInvestorGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<CustomInvestorGoalRepository>(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
    .to(
      withDummies
        ? CustomInvestorGoalDummyRepository
        : CustomInvestorGoalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomInvestorGoalQuery>(TYPES.GET_CUSTOM_INVESTOR_GOAL_QUERY)
    .to(GetCustomInvestorGoalQuery)
    .inSingletonScope();

  container
    .bind<SearchByCustomerStateManager>(TYPES.CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_STATE_MANAGER)
    .to(SearchByCustomerVueStateManager)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<GetCustomInvestorGoalSearchByCustomerQuery>(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
    .to(GetCustomInvestorGoalSearchByCustomerQuery)
    .inSingletonScope();

  container
    .bind<UpdateCustomInvestorGoalCommand>(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_COMMAND)
    .to(UpdateCustomInvestorGoalCommand)
    .inSingletonScope();

  container
    .bind<UpdateCustomInvestorGoalNameCommand>(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_NAME_COMMAND)
    .to(UpdateCustomInvestorGoalNameCommand)
    .inSingletonScope();

  container
    .bind<CreateCustomInvestorGoalCommand>(TYPES.CREATE_CUSTOM_INVESTOR_GOAL_COMMAND)
    .to(CreateCustomInvestorGoalCommand)
    .inSingletonScope();

  container
    .bind<CreateCustomInvestorGoalWithContributionsCommand>(
      TYPES.CREATE_CUSTOM_INVESTOR_GOAL_V2_COMMAND,
    )
    .to(CreateCustomInvestorGoalWithContributionsCommand)
    .inSingletonScope();

  container
    .bind<DeleteCustomInvestorGoalCommand>(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_COMMAND)
    .to(DeleteCustomInvestorGoalCommand)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<TransferBalanceCustomInvestorGoalCommand>(TYPES.TRANSFER_BALANCE_CUSTOM_INVESTOR_GOAL_COMMAND)
    .to(TransferBalanceCustomInvestorGoalCommand)
    .inSingletonScope();

  container
    .bind<UpdatePlanCustomGoalCommand>(TYPES.UPDATE_PLAN_CUSTOM_GOAL_COMMAND)
    .to(UpdatePlanCustomGoalCommand)
    .inSingletonScope();

  container
    .bind<DeleteCustomInvestorGoalStateManager>(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER)
    .to(DeleteCustomInvestorGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<DeleteCustomInvestorGoalStateManagerService>(
      TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE,
    )
    .to(DeleteCustomInvestorGoalStateManagerService)
    .inSingletonScope();
}
