export default {
  GET_CUSTOM_INVESTOR_GOAL_QUERY: Symbol.for('GET_CUSTOM_INVESTOR_GOAL_QUERY'),
  UPDATE_CUSTOM_INVESTOR_GOAL_COMMAND: Symbol.for('UPDATE_CUSTOM_INVESTOR_GOAL_COMMAND'),
  UPDATE_CUSTOM_INVESTOR_GOAL_NAME_COMMAND: Symbol.for('UPDATE_CUSTOM_INVESTOR_GOAL_NAME_COMMAND'),
  CREATE_CUSTOM_INVESTOR_GOAL_COMMAND: Symbol.for('CREATE_CUSTOM_INVESTOR_GOAL_COMMAND'),
  CREATE_CUSTOM_INVESTOR_GOAL_V2_COMMAND: Symbol.for('CREATE_CUSTOM_INVESTOR_GOAL_V2_COMMAND'),
  DELETE_CUSTOM_INVESTOR_GOAL_COMMAND: Symbol.for('DELETE_CUSTOM_INVESTOR_GOAL_COMMAND'),
  TRANSFER_BALANCE_CUSTOM_INVESTOR_GOAL_COMMAND: Symbol.for('TRANSFER_BALANCE_CUSTOM_INVESTOR_GOAL_COMMAND'),
  UPDATE_PLAN_CUSTOM_GOAL_COMMAND: Symbol.for('UPDATE_PLAN_CUSTOM_GOAL_COMMAND'),
  CUSTOM_INVESTOR_GOAL_REPOSITORY: Symbol.for('CUSTOM_INVESTOR_GOAL_REPOSITORY'),
  CUSTOM_INVESTOR_GOAL_STATE_MANAGER: Symbol.for('CUSTOM_INVESTOR_GOAL_STATE_MANAGER'),
  GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY: Symbol.for('GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY'),
  CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_STATE_MANAGER: Symbol.for('CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_STATE_MANAGER'),
  DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER: Symbol.for('DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER'),
  DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE: Symbol.for('DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE'),
};
