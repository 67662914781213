import TYPES from '@/modules/shared/types';

// Domain
import { AllianzRecurringContributionsRepository } from '../../domain/repository';
import { AllianzRecurringContributionsEntity } from '../../domain/entities/allianz-recurring-contributions-entity';
import {
  UpdateAllianzRecurringContributionsEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/update-allianz-recurring-contributions-entity';
import {
  SuspendAllianzRecurringContributionsDto,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/dto/suspend-allianz-recurring-contributions-dto';
import {
  SuspendAllianzRecurringContributionsResponseDto,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/dto/suspend-allianz-recurring-contributions-response-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class AllianzRecurringContributionsHttpRepository
implements AllianzRecurringContributionsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/investment/allianz/customer-recurring-contributions/`;

  get(): Promise<AllianzRecurringContributionsEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  update(payload: UpdateAllianzRecurringContributionsEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }

  suspend(payload: SuspendAllianzRecurringContributionsDto):
    Promise<SuspendAllianzRecurringContributionsResponseDto> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}cancel/`, 'PATCH', payload)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
