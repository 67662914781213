import fixtures from './fixtures.json';
import searchByCustomFixtures from './fixtures2.json';

// Domain
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomInvestorGoalDummyRepository implements CustomInvestorGoalRepository {
  get(): Promise<any> {
    return Promise.resolve(fixtures);
  }

  search(): Promise<Array<CustomInvestorGoalEntity>> {
    return Promise.resolve(searchByCustomFixtures);
  }

  update(): Promise<void> {
    return Promise.resolve();
  }

  updateName(): Promise<void> {
    return Promise.resolve();
  }

  create(): Promise<void> {
    return Promise.resolve();
  }

  create_and_link_with_contributions(): Promise<void> {
    return Promise.resolve();
  }

  delete(): Promise<void> {
    return Promise.resolve();
  }

  transfer(): Promise<void> {
    return Promise.resolve();
  }

  updatePlan(): Promise<void> {
    return Promise.resolve();
  }
}
