import TYPES from '../../types';

// Domain
import { AllianzRecurringContributionsRepository } from '../../domain/repository';
import {
  UpdateAllianzRecurringContributionsEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/update-allianz-recurring-contributions-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateAllianzRecurringContributionsCommand
  extends Command<UpdateAllianzRecurringContributionsEntity> {
  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_REPOSITORY)
  private readonly repository!: AllianzRecurringContributionsRepository;

  internalExecute(payload: UpdateAllianzRecurringContributionsEntity): Promise<unknown> {
    return this.repository.update(payload);
  }
}
