import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AllianzRecurringContributionsVueStateManager from './infrastructure/state/allianz-recurring-contributions-vue-state-manager';
import SetRecurringContributionsVueStateManager from './infrastructure/state/set-recurring-contributions-vue-state-manager';
import {
  AllianzRecurringContributionsHttpRepository,
} from './infrastructure/repository';

// Application
import { SearchAllianzRecurringContributionsQuery } from './application/queries';
import { UpdateAllianzRecurringContributionsCommand, SuspendAllianzRecurringContributionsCommand } from './application/commands';
import { MonthlyCollectionDaysService } from './application/services';

// Domain
import { AllianzRecurringContributionsRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';
import { SetRecurringContributionsStateManager } from './domain/state/set-recurring-contributions-state-manager';

export default function setupAllianzRecurringContributions(container: Container) {
  container
    .bind<StateManager>(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
    .to(AllianzRecurringContributionsVueStateManager)
    .inSingletonScope();

  container
    .bind<SetRecurringContributionsStateManager>(TYPES.SET_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
    .to(SetRecurringContributionsVueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzRecurringContributionsRepository>(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_REPOSITORY)
    .to(AllianzRecurringContributionsHttpRepository)
    .inSingletonScope();

  container
    .bind<SearchAllianzRecurringContributionsQuery>(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
    .to(SearchAllianzRecurringContributionsQuery)
    .inSingletonScope();

  container
    .bind<UpdateAllianzRecurringContributionsCommand>(
      TYPES.UPDATE_ALLIANZ_RECURRING_CONTRIBUTIONS_COMMAND,
    )
    .to(UpdateAllianzRecurringContributionsCommand)
    .inSingletonScope();

  container
    .bind<SuspendAllianzRecurringContributionsCommand>(
      TYPES.SUSPEND_ALLIANZ_RECURRING_CONTRIBUTIONS_COMMAND,
    )
    .to(SuspendAllianzRecurringContributionsCommand)
    .inSingletonScope();

  container
    .bind<MonthlyCollectionDaysService>(
      TYPES.MONTHLY_COLLECTION_DAYS_SERVICE,
    )
    .to(MonthlyCollectionDaysService)
    .inSingletonScope();
}
