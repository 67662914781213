import { SetAllianzRecurringContributionsStateDto } from '../dto/set-allianz-recurring-contributions-state-dto';

export default class SetRecurringContributionsState {
  loaded = false;

  is_loading = false;

  item: SetAllianzRecurringContributionsStateDto = {
    allianz_account_id: '',
    source_account: '',
    policy_number: '',
    periodicity: '',
    last_collection_day: 0,
    last_contribution_amount: '',
    current_payment_dates: '',
    current_total_contribution: '0',
    home_desired: false,
    have_more_than_one_contribution: false,
    wants_more_than_one_contribution: false,
    wants_back_to_one_contribution: false,
    current_contribution: 0,
    contributions: [],
    total_contribution: 0,
    show_suspend_contributions: false,
    contributions_saved: false,
    dates_changed: false,
    show_second_date: false,
  };
}
