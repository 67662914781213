import TYPES from '@/types';

// Domain
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

@Injectable()
export default class MonthlyCollectionDaysService {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-configure-second-contribution-wealth';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  load(): Array<MonthlyCollectionDayOptionEntity> {
    const monthly_collection_days = [...Array(28).keys()].map(
      (x) => ({
        value: x + 1,
        label: `${this.translate('day_label', { day: x + 1 })}`,
      }),
    );
    monthly_collection_days.push({ value: 0, label: this.translate('recurring_not_defined') });
    return monthly_collection_days;
  }

  showDay = (day: MonthlyCollectionDayOptionEntity, selected_day: number): boolean => {
    if (!day || selected_day === 0) return true;
    const invalid_days = [];
    for (let i = -5; i <= 5; i += 1) {
      let invalid_day = selected_day + i;

      if (invalid_day < 1) invalid_day = 28 + invalid_day;

      if (invalid_day > 28) invalid_day -= 28;

      invalid_days.push(invalid_day);
    }

    return !invalid_days.includes(day.value);
  }
}
