import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RetirementInvestorGoalDummyRepository
  from '@/modules/flagship/retirement-investor-goal/infrastructure/repositories/retirement-investor-goal-dummy-repository/retirement-investor-goal-dummy-repository';
import RetirementInvestorGoalHttpRepository
  from '@/modules/flagship/retirement-investor-goal/infrastructure/repositories/retirement-investor-goal-http-repository';
import RetirementInvestorGoalVueStateManager
  from '@/modules/flagship/retirement-investor-goal/infrastructure/state/retirement-investor-goal-vue-state-manager';

// Application
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import UpdatePlanRetirementFundInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/update-plan-retirement-fund-investor-goal-command';
import CreateRetirementInvestorGoalWithContributionsCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/create-retirement-investor-goal-with-contributions-command';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';

// Domain
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import { RetirementInvestorGoalStateManager } from '@/modules/flagship/retirement-investor-goal/domain/state/retirement-investor-goal-state-manager';

export default function setupRetirementInvestorGoal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RetirementInvestorGoalStateManager>(TYPES.RETIREMENT_INVESTOR_GOAL_STATE_MANAGER)
    .to(RetirementInvestorGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<RetirementInvestorGoalRepository>(
      TYPES.RETIREMENT_INVESTOR_GOAL_REPOSITORY,
    ).to(
      withDummies
        ? RetirementInvestorGoalDummyRepository
        : RetirementInvestorGoalHttpRepository,
    ).inSingletonScope();

  container
    .bind<RetirementInvestorGoalCommand>(
      TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND,
    ).to(RetirementInvestorGoalCommand)
    .inSingletonScope();

  container
    .bind<CreateRetirementInvestorGoalWithContributionsCommand>(
      TYPES.CREATE_RETIREMENT_INVESTOR_GOAL_V2_COMMAND,
    ).to(CreateRetirementInvestorGoalWithContributionsCommand)
    .inSingletonScope();

  container
    .bind<GetRetirementInvestorGoalQuery>(
      TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY,
    ).to(GetRetirementInvestorGoalQuery)
    .inSingletonScope();

  container
    .bind<UpdatePlanRetirementFundInvestorGoalCommand>(
      TYPES.UPDATE_PLAN_RETIREMENT_FUND_INVESTOR_GOAL_COMMAND,
    ).to(UpdatePlanRetirementFundInvestorGoalCommand)
    .inSingletonScope();
}
