import Vue from 'vue';

// Domain
import { SetRecurringContributionsStateManager } from '../../domain/state/set-recurring-contributions-state-manager';
import SetRecurringContributionsState from '../../domain/state/set-recurring-contributions-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class SetRecurringContributionsVueStateManager
implements SetRecurringContributionsStateManager {
  private _state: SetRecurringContributionsState = Vue.observable<SetRecurringContributionsState>(
    new SetRecurringContributionsState(),
  );

  private readonly observers: Observer[] = [];

  get state(): SetRecurringContributionsState {
    return this._state;
  }

  set state(value: SetRecurringContributionsState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<SetRecurringContributionsState>) {
    type Keys = keyof SetRecurringContributionsState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
