import TYPES from '../../types';

// Domain
import {
  CustomInvestorGoalWithContributionsDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-with-contributions-dto';
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateCustomInvestorGoalWithContributionsCommand
  extends Command<CustomInvestorGoalWithContributionsDto> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: CustomInvestorGoalRepository;

  internalExecute(payload: CustomInvestorGoalWithContributionsDto) {
    return this.repository.create_and_link_with_contributions(payload);
  }
}
