// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementInvestorGoalDummyRepository
implements RetirementInvestorGoalRepository {
  get(): Promise<RetirementInvestorGoalDto> {
    return Promise.resolve(fixtures);
  }

  create(): Promise<void> {
    return Promise.resolve();
  }

  create_and_link_with_contributions(): Promise<void> {
    return Promise.resolve();
  }

  updatePlan(): Promise<void> {
    return Promise.resolve();
  }
}
