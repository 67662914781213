import TYPES from '../../types';

// Domain
import {
  BucketInvestorGoalWithContributionsDto,
} from '@/modules/flagship/bucket-investor-goal/domain/dtos/bucket-investor-goal-with-contributions-dto';
import {
  BucketInvestorGoalRepository,
} from '@/modules/flagship/bucket-investor-goal/domain/repositories/bucket-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateBucketInvestorGoalWithContributionsCommand
  extends Command<BucketInvestorGoalWithContributionsDto> {
  @Inject(TYPES.BUCKET_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: BucketInvestorGoalRepository;

  internalExecute(payload: BucketInvestorGoalWithContributionsDto) {
    return this.repository.create_and_link_with_contributions(payload);
  }
}
