import TYPES from '../../types';

// Domain
import { AllianzRecurringContributionsRepository } from '../../domain/repository';
import {
  SuspendAllianzRecurringContributionsDto,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/dto/suspend-allianz-recurring-contributions-dto';
import {
  SuspendAllianzRecurringContributionsResponseDto,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/dto/suspend-allianz-recurring-contributions-response-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class SuspendAllianzRecurringContributionsCommand
  extends Command<SuspendAllianzRecurringContributionsDto,
    Promise<SuspendAllianzRecurringContributionsResponseDto>> {
  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_REPOSITORY)
  private readonly repository!: AllianzRecurringContributionsRepository;

  internalExecute(payload: SuspendAllianzRecurringContributionsDto):
    Promise<SuspendAllianzRecurringContributionsResponseDto> {
    return this.repository.suspend(payload);
  }
}
