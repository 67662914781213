import { AllianzRecurringContributionsEntity } from '../entities/allianz-recurring-contributions-entity';

export default class State {
  loaded = false;

  is_loading = false;

  item: AllianzRecurringContributionsEntity = {
    home_desired: false,
    total_contribution: 0,
    contributions: [],
  };
}
