import start from './start.json';
import personalInformation from './personal-information';
import profiling from './profiling';
import beneficiaries from './beneficiaries.json';
import contributions from './contributions';
import financialInformation from './financial-information.json';
import collection from './collection';
import additionalInformation from './additional-information';
import beforeStart from './before-start';
import investments from './investments';
import ready from './ready.json';
import errorAllianz from './error-allianz.json';
import manualRevission from './manual-revission.json';
import almostCompleted from './almost-completed.json';
import aboutToComplete from './about-to-complete.json';
import congratulations from './congratulations.json';
import geolocation from './geolocation.json';
import credentials from './credentials.json';
import creatingAccount from './creating-account.json';
import allianz from './allianz';
import kuspitAccountLinking from '@/locales/es/components/contract-savings/kuspit-account-linking';

export default {
  'contract-savings': {
    allianz: {
      moto: 'Ahorro - Inversión Allianz',
      steps: [
        'Información personal',
        'Perfilamiento',
        'Beneficiarios',
        'Inversiones',
        'Aportaciones',
        'Cobranza',
      ],
      error_on_policy_creation: 'Ha ocurrido un error al generar la póliza, por favor intente nuevamente.',
      disclaimer: 'Este proceso está conectado al módulo de contratación de Allianz de México.',
      ...allianz,
    },
    kuspit: {
      moto: 'Kuspit Casa de Bolsa',
      steps: [
        'Inicio',
        'Carga de documentos',
        'Contacto',
        'Información adicional',
        'Creación de la cuenta',
      ],
      disclaimer: 'Este proceso está conectado al módulo de contratación de Kuspit Casa de Bolsa.',
    },
    continue: 'Continuar',
    back: 'Regresar',
    loading: 'Cargando...',
    error_on_save_step: 'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
    ...start,
    ...personalInformation,
    ...beforeStart,
    ...investments,
    ...ready,
    ...errorAllianz,
    ...manualRevission,
    ...profiling,
    ...beneficiaries,
    ...contributions,
    ...financialInformation,
    ...collection,
    ...additionalInformation,
    ...almostCompleted,
    ...aboutToComplete,
    ...congratulations,
    ...geolocation,
    ...credentials,
    ...creatingAccount,
    ...kuspitAccountLinking,
  },
};
