import TYPES from '@/modules/shared/types';

// Domain
import {
  BucketInvestorGoalRepository,
} from '@/modules/flagship/bucket-investor-goal/domain/repositories/bucket-investor-goal-repository';
import {
  BucketInvestorGoalWithContributionsDto,
} from '@/modules/flagship/bucket-investor-goal/domain/dtos/bucket-investor-goal-with-contributions-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class BucketInvestorGoalHttpRepository implements BucketInvestorGoalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint_v2 = `${process.env.VUE_APP_API_URL}/api/v2/app/flagship/bucket-investor-goal/`;

  create_and_link_with_contributions(payload: BucketInvestorGoalWithContributionsDto):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint_v2}link-wealth-with-contribution/`, 'POST', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
