import ALLIANZ_ACCOUNT from './allianz-account/types';
import ALLIANZ_ACCOUNT_REPORT from './allianz-account-report/types';
import ALLIANZ_EARNINGS_REPORT from './allianz-earnings-report/types';
import ALLIANZ_ACCOUNT_MOVEMENT_REPORT from './allianz-account-movement-report/types';
import TRANSACTION_TYPES from './transaction/types';
import INVESTMENT_TYPE_TYPES from './investment-type/types';
import ALLIANZ_FUND_TYPES from './allianz-funds/types';
import ALLIANZ_OPERATION_DATES_TYPES from './allianz-operation-dates/types';
import ALLIANZ_TRANSACTION_TYPES from './transaction-type/types';
import ALLIANZ_TRANSACTION_STATUS from './transaction-status/types';
import ALLIANZ_CURRENCY_TYPE from './currency-type/types';
import ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_TYPE from './allianz-customer-available-balance/types';
import ALLIANZ_POLICY_ISSUANCE_STATUS_TYPES from './allianz-policy-issuance-status/types';
import ON_BOARDING_STATUS_TYPES from './on-boarding-status/types';
import ALLIANZ_POLICY_TYPES from './policy/types';
import TOTAL_WITHDRAWAL from './total_withdrawal/types';
import ALLIANZ_ACCOUNT_STATEMENT_TYPES from './allianz-account-statement/types';
import ACTIVATE_SOWOS_MODERATE_TYPES from './activate-sowos-moderate/types';
import SPECIAL_CONTRIBUTION_TYPES from './special-contribution/types';
import ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_TYPES from './allianz-request-for-movments-to-policy/types';
import ACCESS_MORE_STRATEGIES_TYPES from './access-more-strategies/types';
import INVESTMENT_TYPE_HIRING_STEP_TYPES from './investment-type-hiring-step/types';
import INVESTMENT_PRODUCT_FUND_TYPES from './investment-product-fund-type/types';
import ALLIANZ_RECURRING_CONTRIBUTION_TYPES from './recurring-contributions/types';

export default {
  ...ALLIANZ_ACCOUNT,
  ...ALLIANZ_ACCOUNT_REPORT,
  ...ALLIANZ_EARNINGS_REPORT,
  ...ALLIANZ_ACCOUNT_MOVEMENT_REPORT,
  ...TRANSACTION_TYPES,
  ...INVESTMENT_TYPE_TYPES,
  ...ALLIANZ_FUND_TYPES,
  ...ALLIANZ_OPERATION_DATES_TYPES,
  ...ALLIANZ_TRANSACTION_TYPES,
  ...ALLIANZ_TRANSACTION_STATUS,
  ...ALLIANZ_CURRENCY_TYPE,
  ...ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_TYPE,
  ...ALLIANZ_POLICY_ISSUANCE_STATUS_TYPES,
  ...ALLIANZ_POLICY_TYPES,
  ...ON_BOARDING_STATUS_TYPES,
  ...TOTAL_WITHDRAWAL,
  ...ALLIANZ_ACCOUNT_STATEMENT_TYPES,
  ...ACTIVATE_SOWOS_MODERATE_TYPES,
  ...SPECIAL_CONTRIBUTION_TYPES,
  ...ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_TYPES,
  ...ACCESS_MORE_STRATEGIES_TYPES,
  ...INVESTMENT_TYPE_HIRING_STEP_TYPES,
  ...INVESTMENT_PRODUCT_FUND_TYPES,
  ...ALLIANZ_RECURRING_CONTRIBUTION_TYPES,
};
