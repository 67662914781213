import TYPES from '../../types';

// Domain
import {
  RetirementInvestorGoalWithContributionsDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-with-contributions-dto';
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateRetirementInvestorGoalWithContributionsCommand
  extends Command<RetirementInvestorGoalWithContributionsDto> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalRepository;

  internalExecute(payload: RetirementInvestorGoalWithContributionsDto): Promise<void> {
    return this.repository.create_and_link_with_contributions(payload);
  }
}
