import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import BucketInvestorGoalHttpRepository
  from '@/modules/flagship/bucket-investor-goal/infrastructure/repositories/bucket-investor-goal-http-repository';

// Application
import CreateBucketInvestorGoalWithContributionsCommand
  from '@/modules/flagship/bucket-investor-goal/application/command/create-bucket-investor-goal-with-contributions-command';

// Domain
import {
  BucketInvestorGoalRepository,
} from '@/modules/flagship/bucket-investor-goal/domain/repositories/bucket-investor-goal-repository';

export default function setupCustomInvestorGoal(container: Container) {
  container
    .bind<BucketInvestorGoalRepository>(TYPES.BUCKET_INVESTOR_GOAL_REPOSITORY)
    .to(BucketInvestorGoalHttpRepository)
    .inSingletonScope();

  container
    .bind<CreateBucketInvestorGoalWithContributionsCommand>(
      TYPES.CREATE_BUCKET_INVESTOR_GOAL_V2_COMMAND,
    )
    .to(CreateBucketInvestorGoalWithContributionsCommand)
    .inSingletonScope();
}
